<template>
	<div class="ball-page" ref="default_drag_comp" @click="goNext">
		<div class="title">
			<span class="title_span">{{ ball.countUsable }}</span>
		</div>
		<img src="~@/assets/images/img_009.gif" class="icon" />
	</div>
</template>
<script>
	export default {
		name: 'Drag',
		props: {
			ball: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		data: function() {
			return {
				flags: false,
				position: {
					x: 0,
					y: 0
				},
				nx: '',
				ny: '',
				xPum: '',
				yPum: ''
			};
		},
		methods: {
			goNext() {
				this.$emit('click');
			}
		}
	};
</script>
<style lang="scss" scoped>
	@function rem375($val) {
		@return $val * 100 /37.5 /100 * 1rem;
	}

	.ball-page {
		// background-color: #fff;
		// box-shadow: 0 rem375(2) rem375(10) 0 rgba(55, 63, 82, 0.1);
		position: fixed;
		z-index: 999;
		width: rem375(85);
		height: rem375(48);
		// border-radius: 50%;
		right: rem375(0);
		bottom: rem375(100);
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;

		.icon {
			width: rem375(70);
		}

		.title {
			padding: 0.0266rem 0.1333rem;
			text-align: center;
			position: absolute;
			top: -0.2666rem;
			right: 0.3733rem;
			box-sizing: border-box;

			background: linear-gradient(180deg, #FF5400 0%, #D6300A 100%);
			border-radius: 10px;
			z-index: 1;

			.title_span {
				color: #FD5042;
				font-size: 0.32rem;
				font-weight: 600;
				color: #FFFFFF;
				line-height: 0.4533rem;
			}
		}
	}
</style>
